<template lang="pug">
v-app
  v-navigation-drawer(
    app
    :value="drawer"
    :mobile-breakpoint="0"
  )
    v-list-item
      v-list-item-content
        v-img(src="./assets/img/logo-zest-x2.png" height="48" contain)
    v-list
      template(v-if='!authenticated')
        v-list-item(link to='/login')
          v-list-item-icon
            v-icon mdi-account
          v-list-item-content
            v-list-item-title Login
      template(v-else)
        v-list-item(link to='/profile')
          v-list-item-icon
            v-icon mdi-account
          v-list-item-content
            v-list-item-title {{ user.displayName || sensitive(user.phoneNumber) || sensitive(user.email) }}
        v-list-item(link to='/users')
          v-list-item-icon
            v-icon mdi-account-multiple-outline
          v-list-item-content
            v-list-item-title Clients
        v-list-item(link to='/care-providers')
          v-list-item-icon
            v-icon mdi-medical-bag
          v-list-item-content
            v-list-item-title Care Providers
        v-list-item(link to='/vendors' v-if="granted(p.listVendors)")
          v-list-item-icon
            v-icon mdi-hospital-building
          v-list-item-content
            v-list-item-title Vendors
        v-list-item(link to='/admins' v-if="grantedAny(p.manageAcl_vendor, p.manageAcl_insurance, p.manageAcl_all)")
          v-list-item-icon
            v-icon mdi-account-tie
          v-list-item-content
            v-list-item-title Admins
        v-list-item(link to='/reports' v-if="grantedAny(p.sendReports, p.sendReports_all)")
          v-list-item-icon
            v-icon mdi-file-chart-outline
          v-list-item-content
            v-list-item-title Reports
        v-list-item(link to='/refcodes' v-if="grantedAny(p.refcodes, p.refcodes_all)")
          v-list-item-icon
            v-icon mdi-barcode
          v-list-item-content
            v-list-item-title Ref codes
        v-list-item(link to='/payments/pending' v-if="grantedAny(p.payments_vendor, p.payments_all, p.payments_insurance)")
          v-list-item-icon
            v-icon mdi-cash-clock
          v-list-item-content
            v-list-item-title Payments
        v-list-item(link to='/promo-codes' v-if="granted(p.payments_vendor, p.payments_all, p.payments_insurance)")
          v-list-item-icon
            v-icon mdi-percent
          v-list-item-content
            v-list-item-title Promo codes
        v-list-item(link to='/pricing' v-if="grantedAny(p.readPricing_vendor, p.readPricing_all)")
          v-list-item-icon
            v-icon mdi-currency-ngn
          v-list-item-content
            v-list-item-title Pricing
        v-list-item(link to='/consultations' v-if="grantedAny(p.readConsultationReports_vendor, p.readConsultationReports_insurance, p.readConsultationReports_all)")
          v-list-item-icon
            v-icon mdi-video
          v-list-item-content
            v-list-item-title Consultations
        v-list-item(link to='/biomarkers-overview' v-if="grantedAny(p.biomarkersOverview_vendor, p.biomarkersOverview_insurance, p.biomarkersOverview_all)")
          v-list-item-icon
            v-icon mdi-chart-timeline-variant-shimmer
          v-list-item-content
            v-list-item-title Biomarkers Overview
        v-list-item(link to='/services' v-if="granted(p.fullAccess)")
          v-list-item-icon
            v-icon mdi-account-switch
          v-list-item-content
            v-list-item-title Service
        v-list-item(link to='/body-summary-test' v-if="granted(p.fullAccess)")
          v-list-item-icon
            v-icon mdi-test-tube
          v-list-item-content
            v-list-item-title Body Summary Test

        v-list-item
          v-list-item-content
            v-list-item-title Sensitive mode
          v-list-item-action
            v-switch(dense v-model="sensitiveSwitcher")

  v-main.admin-page
    router-view.px-8.py-10

  v-snackbar(v-model="globalSnackbar.enabled" :timeout="globalSnackbar.timeout" :color="globalSnackbar.color") {{ globalSnackbar.text }}
    template(v-slot:action="{ attrs }")
      v-btn(text="" v-bind="attrs" @click="hideGlobalSnackbar") Close
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import { onAuthStateChanged } from 'firebase/auth';
import { knownPermissions } from '@/helpers/const';

export default {
  name: 'App',

  data: () => ({
    p: knownPermissions,
    links: [
      // ['mdi-inbox-arrow-down', 'Inbox'],
      // ['mdi-send', 'Send'],
      // ['mdi-delete', 'Trash'],
      // ['mdi-alert-octagon', 'Spam'],
    ],
  }),

  computed: {
    ...mapGetters(['authenticated', 'granted', 'grantedAny']),
    ...mapState(['user', 'drawer', 'globalSnackbar']),

    sensitiveSwitcher: {
      get() {
        return this.maskedSensitive;
      },
      set(val) {
        return this.setMaskSensitive(val);
      },
    },
  },

  methods: {
    ...mapActions(['hideGlobalSnackbar']),
    toggleDrawer() {
      this.$store.commit('TOGGLE_DRAWER');
    },
  },

  beforeCreate() {
    if (localStorage.user) {
      this.$store.commit('SET_FB_USER', JSON.parse(localStorage.user));
    }
  },

  created() {
    onAuthStateChanged(this.$fbauth, (user) => {
      if (!user) {
        localStorage.removeItem('user');
        this.$store.commit('SET_FB_USER', null);
        this.$store.commit('SET_PERMISSIONS', {});
        this.$heap('resetIdentity');
        return;
      }

      this.$heap('identify', user.uid);
      this.$store.commit('SET_FB_USER', user.toJSON());
      localStorage.setItem('user', JSON.stringify(user.toJSON()));
      this.$api('permissions').then(({ data }) => {
        this.$store.commit('SET_PERMISSIONS', data.permissions);
        this.$store.commit('SET_DB_USER', data.user);
      });
    });
  },
};
</script>

<style lang="scss" type="text/sass">
  //@import "assets/scss/styles";
  //.v-navigation-drawer {
  //  background: url('/assets/img/med-pattern.jpg');
  //}
</style>
